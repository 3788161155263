<template>
    <div>
        <mobile-layout source="sub" tab="7">
            <v-container>
                <v-row justify="center" no-gutters class="flex mt-10">
                    <v-col cols="12" align="center" justify="center">
                        <h1>中国医疗器械产业峰会</h1>
                        <h2>暨第二届国药器械生态大会</h2>
                    </v-col>
                    <span class="caption light-blue--text text--lighten-4 block"
                        >CHINA MEDICAL DEVICE INDUSTRY SUMMIT & THE
                    </span>
                    <span class="caption light-blue--text text--lighten-4 block"
                        >2ND SINOPHARN-CMDC ECOSYSTEM CONFERENCE
                    </span>
                </v-row>
                <v-row
                    justify="center"
                    no-gutters
                    class="
                        flex
                        mt-10
                        light-blue--text
                        text--lighten-4
                        margin-bottom
                    "
                    style="text-indent: 2em"
                >
                    <p>
                        中国医疗器械产业峰会暨第二届国药器械生态大会，是由中国医疗器械有限公司为凝聚行业智慧和力量，推动全产业链和合共赢而主办的年度医疗器械行业盛会。
                    </p>
                    <p>
                        本届大会以“链动创新生态”为主题，邀请政府领导、行业专家、企业高管及优秀产业链企业代表，从政策解读、创新前瞻、价值赋能、数字化营销、投资策略等多个维度开展讨论，整合创新链、价值链、产业链，洞见发展趋势、促进交流合作，凝聚行业智慧和力量。
                    </p>
                    <p>
                        诚邀您的莅临，相聚有福之州！携手共创医疗器械行业发展新篇章！
                    </p>
                </v-row>
            </v-container>
        </mobile-layout>
    </div>
</template>

<script>
import MobileLayout from '../../components/MobileLayout.vue'
export default {
    components: { MobileLayout },
    name: 'About',

    data: () => ({}),
}
</script>

<style lang="scss" scoped>
.margin-bottom {
    margin-bottom: 200px;
}
</style>
